import Vue from "vue";

class ErrorHelper extends Vue {
  message(e, alterMsg) {
    let msg = alterMsg;
    
    if (e && e.response) {
      const response = e.response;
      
      if (response.status !== 200 && response.data) {
        const data = response.data;
        
        if (data.message && data.message !== "") {
          msg = data.message;
        } 
        else if (data.errorMessage && data.errorMessage !== "") {
          msg = data.errorMessage;
        }
      }
    }
    
    return msg;
  }

  isSessinExpired(e) {
    if (e && e.response && e.response.status && e.response.status == 401) {
      window.dispatchEvent(
        new CustomEvent("check-logout", {
          detail: {
            storage: e,
          },
          from: "isSessinExpired",
        })
      );
      return true;
    } else{
      return false
    }
  }
  signInError(e) {
    if (!e || !e.response || e.response.data) {
      return "messages.sign-in-error-default";
    }
    let code = e.response.status;
    let msg = e.response.data.message;
    if (code == 403) {
      switch (msg) {
        case ("UnverifiedUser", "BlockedUser"):
          return "messages.sign-in-403-" + msg;
        default:
          return "messages.sign-in-error-default";
      }
    }
    return "messages.sign-in-error-default";
  }

  signUpError(code) {
    switch (code) {
      case (302, 409):
        return "messages.sign-up-" + code;
      default:
        return "sign-up-error-default";
    }
  }
}

export default new ErrorHelper();
