import http from "@/http-common";
import Api from "@/api/methods/Api";

export const defaultParams = {
	offerStatus: "active",
	needPhoto: false,
	needStatusScale: false
};

class ApiOffers extends Api {
	createNewOffer(token, params) {
		return http.post("/api/createNewOffer", params);
	}

	startPurchaseProcess(params) {
		return http.post("/api/startPurchaseProcess", params);
	}

	getDataToPayForOffer(params) {
		return http.post("/api/getDataToPayForOffer", params);
	}

	chargeForServiceOrder(params) {
		return http.post("/api/chargeForServiceOrder", params);
	}

	getOffersForBuyer(params) {
		return http.post("/api/getOffersForBuyer", params);
	}

	getOffersForSeller(params) {
		return http.post("/api/getOffersForSeller", params);
	}

	getOffersForSellerByNote(params) {
		return http.post("/api/getOffersForSellerByNote", params);
	}

	getOffersHistoryForSeller(query) {
		return http.get("/api/getOffersHistoryForSellerByLastOffer/" + query);
	}

	confirmOfferByUser(model) {
		return http.post("/api/confirmOfferByUser", model);
	}

	confirmOfferByVendor(model) {
		return http.post("/api/confirmOfferByVendor", model);
	}

	rejectOffer(model) {
		return http.post("/api/rejectOffer", model);
	}

	cancelOfferByUser(model) {
		return http.post("/api/cancelOfferByUser", model);
	}

	cancelOfferByVendor(model) {
		return http.post("/api/cancelOfferByVendor", model);
	}

	createCounterOffer(model) {
		return http.post("/api/createCounterOffer", model);
	}

	checkPossibilityForAcceptingOffer(offerId) {
		return http.get("/api/checkPossibilityForAcceptingOffer/" + offerId);
	}

	checkOfferHasActiveStatus(offerId) {
		return http.get("/api/checkOfferHasActiveStatus/" + offerId);
	}

	getDataForSellerCounterOffer(offerId) {
		return http.get("/api/getDataForSellerCounterOffer/" + offerId);
	}

	checkOfferAwaitingPayment(offerId) {
		return http.get("/api/checkOfferAwaitingPayment/" + offerId);
	}

	checkOfferHasConfirmedStatus(offerId) {
		return http.get("/api/checkOfferHasConfirmedStatus/" + offerId);
	}

	cancelOfferByUser(model) {
		return http.post("/api/cancelOfferByUser", model);
	}

	getDataForOfferCreation(offerId) {
		return http.get("/api/getDataForOfferCreation/" + offerId);
	}

	requestRefundByUser(orderNumber) {
		return http.post("/api/requestRefundByUser/" + orderNumber);
	}

	getDataToAcceptAgreementForOffer(offerId) {
		return http.get("/api/getDataToAcceptAgreementForOffer/" + offerId);
	}

	acceptAgreementForOffer(offerId) {
		return http.get("/api/acceptAgreementForOffer/" + offerId);
	}

	checkOfferStillInEscrow(offerId) {
		return http.get("/api/checkOfferStillInEscrow/" + offerId);
	}

	completeOfferInEscrow(offerId) {
		return http.get("/api/completeOfferInEscrow/" + offerId);
	}
}

export default new ApiOffers();
