import axios from "axios";
import Api from "@/api/methods/Api";
import {
    GET_REPORT_REDEEMING_API,
    GET_REPORT_SUMMARY_API,
} from "@/api/Constants";
import http from "@/http-common";

class ApiReports extends Api {
    getRedeemingReportForApi(key) {
        var ssss = axios.create({
            baseURL: process.env.VUE_APP_EXT_API_URL + "/",
            headers: {
                "Content-type": "application/json",
                "x-requested-with": "XMLHttpRequest",
                "auth-signature": "3c776514-505c-4395-adaf-fa7c30200b19",
            },
        });
        return ssss.get(GET_REPORT_REDEEMING_API + key, this.keyHeaders());
    }

    getgetSummaryReportForApi(key) {
        var ssss = axios.create({
            baseURL: process.env.VUE_APP_EXT_API_URL + "/",
            headers: {
                "Content-type": "application/json",
                "x-requested-with": "XMLHttpRequest",
                "auth-signature": "3c776514-505c-4395-adaf-fa7c30200b19",
            },
        });
        return ssss.get(GET_REPORT_SUMMARY_API + key, this.keyHeaders());
    }

    getReportsFromJobs() {
        return http.get("/api/getReportsFromJobs");
    }

    saveCallOfReportFromJobs(model) {
        return http.post("/api/saveCallOfReportFromJobs", model);
    }

    saveReportParams(paramsList) {
        return http.post("/api/saveReportParams", paramsList);
    }
}

export default new ApiReports();
