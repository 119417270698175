<template>
    <div class="modal modal-bg" :class="{ show: open }">
        <modal v-if="open" modal-with-card card-classes="md-card-login" type="modal-signup" @close="closeModal" >
            <template #header>
                <md-card-header class="md-card-header-cyan m-0">
                    <md-button class="md-simple md-close md-white md-just-icon md-round modal-card-button mt-10" @click="closeModal" >
                        <md-icon class="opacity-100">{{ $t("icon.clear") }}</md-icon>
                    </md-button>
                    <h4 class="card-title mt-0">Reports</h4>
                </md-card-header>
            </template>

            <template #body>
                <div class="reports-modal-container section">
                    <div v-if="step == 'reportsList'" class="md-layout">
                        <div class="md-layout-item md-size-100" v-if="loaded && reportsList && reportsList.length > 0">
                            <div class="nu-simple-list">
                                <div class="nu-simple-list-item-block over mb-15" v-for="report in reportsList" :key="report.jobName">
                                    <div class="nu-simple-list-item-text">
                                        <div class="nu-simple-list-item-name">{{report.friendlyJobName}}</div>
                                        <div class="nu-simple-list-item-description" v-if="report.description">{{report.description}}</div>
                                    </div>
                                    <div class="nu-simple-list-item-btn al-right">
                                        <md-button class="md-cyan-900 button-border-cyan-900" @click="selectReport(report)">Select</md-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md-layout-item md-size-100" v-if="loaded && !(reportsList && reportsList.length > 0)">
                            <md-empty-state>
                                <h5>No reports yet</h5>
                            </md-empty-state>
                        </div>
                    </div>

                    <div v-if="step == 'reportSelected'" class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-button class="md-white button-border-light-gray" @click="returnToReportsList">
                                <i class="fa-solid fa-arrow-left-long"></i> To Reports
                            </md-button>
                        </div>
                        <div class="md-layout-item md-size-100 bold">
                            <md-empty-state>
                                <h5 class="color-d-blue mt-0 mb-0">Your request has been successfully processed.</h5>
                                <h5 class="color-d-blue mt-0 mb-0">The report will be sent to you by email.</h5>
                            </md-empty-state>
                        </div>
                    </div>

                    <div v-if="step == 'reportParams'" class="md-layout">
                        <div v-for="param in reportParams" :key="param.param" class="md-layout-item md-size-100 pad-0">
                            <div class="md-layout-item md-size-100 mb-5">
                                <label class="modal-label text-capitalize">{{param.friendlyParamName}}</label>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <md-field :class="{ 'md-error': param.error }">
                                    <md-textarea class="mb-0" v-model.trim="param.paramValue" maxlength="500" md-counter="false"
                                                 :id="param.param" :name="param.param" @keyup="changeParamValue(param)"></md-textarea>
                                </md-field>
                                <div class="text-danger" v-if="param.error">
                                    {{param.error}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div v-if="step != 'reportParams'">
                    <md-button class="md-white button-border-light-gray" @click="closeModal">Close</md-button>
                </div>
                <div v-if="step == 'reportParams'">
                    <md-button class="md-cyan-900 button-border-cyan-900 login-button-padding-bottom" @click="saveReportParams">
                        Save
                    </md-button>
                    <md-button class="md-white button-border-light-gray" @click="returnToReportsList">
                        Cancel
                    </md-button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Mixins from "@/plugins/basicMixins";
import {ErrorHelper} from "@/api/helpers";
import ApiReports from "@/api/methods/ApiReports";

export default {
    name: "ModalReports",
    components: {Modal},
    mixins: [Mixins.ShowToastr, Mixins.Loading, Mixins.SetPath],
    props: {
        open: Boolean,
    },
    data() {
        return {
            loaded: false,
            reportsList: [],
            reportParams: [],
            step: "reportsList", // "reportsList", "reportParams", "reportSelected"
        };
    },
    methods: {
        getReportsFromJobs() {
            this.showLoading();
            ApiReports.getReportsFromJobs()
                .then((response) => {
                    this.reportsList = response.data;
                    this.loaded = true;
                    this.hideLoading();
                })
                .catch((e) => {
                    this.hideLoading();
                    let msg = ErrorHelper.message(e, null);
                    if (msg) {
                        this.showError(this.name, msg);
                    }
                });
        },
        closeModal() {
            this.$emit("close");
        },
        selectReport(report) {
            this.showLoading();
            var model = {
                jobName: report.jobName,
            };
            ApiReports.saveCallOfReportFromJobs(model)
                .then((response) => {
                    let params = response.data;
                    if (params.length == 0) {
                        this.step = "reportSelected";
                    } else {
                        params.forEach(function(param) {
                            param.jobName = report.jobName;
                            param.error = "";
                        });
                        this.step = "reportParams";
                    }
                    this.reportParams = params;
                    this.hideLoading();
                })
                .catch((e) => {
                    this.hideLoading();
                    let msg = ErrorHelper.message(e, null);
                    if (msg) {
                        this.showError(this.name, msg);
                    }
                });
        },
        returnToReportsList(){
            this.step = "reportsList";
        },
        saveReportParams(){
            if (this.checkHasParamEmptyValues()) {
                return;
            }

            this.showLoading();
            ApiReports.saveReportParams(this.reportParams)
                .then((response) => {
                    this.step = "reportSelected";
                    this.hideLoading();
                })
                .catch((e) => {
                    this.hideLoading();
                    let msg = ErrorHelper.message(e, null);
                    if (msg) {
                        this.showError(this.name, msg);
                    }
                });
        },
        checkHasParamEmptyValues(){
            let hasError = false;
            let paramValueCount = 0;
            let params = this.reportParams;
            params.forEach(function(param) {
                if (param.paramValue) {
                    paramValueCount++;
                    param.error = "";
                } else {
                    param.error = "Please enter a value";
                }
            });
            if (paramValueCount < this.reportParams.length) {
                hasError = true;
            } else {
                hasError = false;
            }
            this.reportParams = params;
            return hasError;
        },
        changeParamValue(param){
            if (param.paramValue) {
                param.error = "";
            } else {
                param.error = "Please enter a value";
            }
        }
    },
    mounted() {
        this.getReportsFromJobs();
    },
};
</script>
